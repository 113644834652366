.planoExpanded {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999
}

.planoActivebutton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10000; /* Asegurar que el botón aparezca encima del componente */
}

.btnClearFilter{
  border: 1px solid #ffffff;
  border-radius: 4px;
  color: #ffffff;
  padding: 5px 6px;
  height: 29px;
  border-bottom-width: 2px;
  font-size: 1.3rem;
  background: #46accfbd;
  margin-top: 0.5rem !important;
}
.imguser {
  width: 30px;
  border-radius: 50%;
}
.nav > li > a {
  position: relative;
  display: block;
  padding: 2px 15px;
}

@media (max-width: 450px) {
  .SelectTipos{
    width: 45% !important;
  }
  .main-panel .navbar {
    height: 2rem;
  }
  .navbar-header .navbar-toggle {
    margin: 5px 9px 0px 0;
  }
  .navbar .navbar-brand {
    margin: 0px 0px;
  }
  .main-panel {
    margin-bottom: 3rem;
  }
 
  .card-stats .stats {
    font-size: 0.8rem !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
  .card-stats .stats i {
    font-size: 1rem !important;
  }
}
/*TABLE MATERIAL*/
.MuiTableCell-root {
  font-size: 1.2rem !important;
  padding: 5px !important;
}

/*SIDEBAR USER BOX*/
.middle .icono {
  color: white;
  font-size: 30px;
  margin: 29px 0px 0px -13px;
  opacity: 0.5;
}
.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  top: 24%;
  left: 50%;
  margin: auto;
  border: solid;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  background-color: #0404047a;
  cursor: pointer;
}
.contImg{
  width: 100px;
  margin: auto;
}
.contImg:hover .image {
  opacity: 0.3;
}

.contImg:hover .middle {
  opacity: 1;
}

#userConfig .tooltip-inner{
  background-color: white;
}
#userConfig .tooltip-arrow{
  border-top-color: white;
}
#userConfig p{
  line-height: 1;
  font-size: 1.3rem;
  padding-top: 3px;
  color: #717171;
  cursor: pointer;
  padding: 0.5em;
  border-radius: 3px;
  margin: 0;
}
#userConfig p:hover{
  color: white;
  background-color: black;
}
.userBox {
  top: 75vh;
  position: sticky;
  text-align: center;
}

.userBox img {
  border-radius: 50%;
  width: 100px;
  margin: auto;
  border: solid;
  cursor: pointer;
  opacity: 1;
  transition: .5s ease;
  backface-visibility: hidden;
}
.userBox i {
  font-size: 2rem;
  font-weight: 600;
  vertical-align: text-bottom;
}
.userBox hr {
  width: 80%;
  border-top: 1px solid #ffffff85;
}
/* INICIO PAGINA LOGIN */
.loginpage {
  height: 100vh;
}
.inner-bg {
  /* padding: 60px 0 0 0; */
  position: absolute;
  top: 10%;
  /* width: 100%; */
  left: 50%;
  margin-left: -185px;
}
.form-top {
  overflow: hidden;
  padding: 0 25px 15px 25px;
  background: #444;
  background: rgba(0, 0, 0, 0.5);
  -moz-border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  text-align: left;
}
.form-top-left {
  float: left;
  width: 75%;
  padding-top: 25px;
}
.form-top-left p {
  color: white;
  opacity: 0.8;
}
.form-top-right {
  float: left;
  width: 25%;
  padding-top: 5px;
  font-size: 66px;
  color: #fff;
  line-height: 100px;
  text-align: right;
  opacity: 0.3;
}
.form-bottom {
  padding: 25px 25px 30px 25px;
  background: #444;
  background: rgba(0, 0, 0, 0.43);
  -moz-border-radius: 0 0 4px 4px;
  -webkit-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  text-align: left;
}
button.btnlogin {
  height: 50px;
  margin: 0;
  padding: 0 20px;
  vertical-align: middle;
  background: #de615e;
  border: 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 50px;
  color: #fff;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  text-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}
.form-bottom form button.btnlogin {
  width: 100%;
}
.loginpage {
  background-image: url("https://whats-up.sedus.com/wp-content/uploads/2019/05/SedusSmartOffice_Dogern_Intern_Office-JPG_17473.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
/* FIN PAGINA LOGIN */

.btnAplicar,
.btnCancelar {
  width: 50px;
}

#btnsetting {
  line-height: 30px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 5px 15px;
  position: relative;
  display: block;
  padding: 10px 15px;
  color: #ffffff;
  opacity: 0.86;
  border-radius: 4px;
  cursor: pointer;
}
#btnsetting i {
  font-size: 28px;
  float: left;
  margin-right: 15px;
  line-height: 30px;
  width: 30px;
  text-align: center;
}
#btnsetting p {
  margin: 0;
  line-height: 30px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: initial;
}
.fixed-plugin hr {
  width: 80%;
  border-top: 1px solid #ffffff85;
  margin-top: 5px;
  margin-bottom: 5px;
}
.icon-small {
  font-size: 1em;
  width: 3em;
}



.SelectTipos {
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid #ffffff;
  border-radius: 4px;
  color: #717171;
  padding: 5px 6px;
  height: 29px;
  box-shadow: none;
  font-weight: 600;
  margin-top: 0.5rem !important;
  border-bottom-style: solid;
  border-bottom-color: #46accf;
  border-bottom-width: 2px;
  width: 20%;
  margin-right: 1rem;
  border-right-style: solid;
  border-right-color: #46accf;
  border-right-width: 2px;
  font-size: 1.3rem;
}

.SelectTipos:hover {
  box-shadow: none;
  border-bottom-color: red;
  border-bottom-width: 2px;
  border-right-color: red;
  border-right-width: 2px;

  transition: linear 300ms;
}
.SelectTipos:focus {
  box-shadow: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-style: solid;
  border-bottom-color: #46accf;
  border-bottom-width: 2px;
}

.BoxSelect {
  width: 100%;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}


.marginauto {
  margin: auto !important;
}
.float-none {
  float: none;
}
.condicion .group {
  background: #faad14 !important;
}
.accion .group {
  background: #742faf !important;
}
.color-line-azure {
  background: #f7f9fa;
  height: 6px;
  background-image: -webkit-linear-gradient(
    left,
    #f80000,
    #ff0000 25%,
    #9200a4 40%,
    #339ac9 55%,
    #47cdff 65%,
    #65d3ff 75%,
    #63dfff 85%,
    #3db5d6 100%
  );
  background-image: -moz-linear-gradient(
    left,
    #f80000,
    #ff0000 25%,
    #9200a4 40%,
    #339ac9 55%,
    #47cdff 65%,
    #65d3ff 75%,
    #63dfff 85%,
    #3db5d6 100%
  );
  background-image: -ms-linear-gradient(
    left,
    #f80000,
    #ff0000 25%,
    #9200a4 40%,
    #339ac9 55%,
    #47cdff 65%,
    #65d3ff 75%,
    #63dfff 85%,
    #3db5d6 100%
  );
  background-image: linear-gradient(
    to right,
    #f80000,
    #ff0000 25%,
    #9200a4 40%,
    #339ac9 55%,
    #47cdff 65%,
    #65d3ff 75%,
    #63dfff 85%,
    #3db5d6 100%
  );
  background-size: 100%;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}

.color-line-black {
  background: #f7f9fa;
  height: 6px;
  background-image: -webkit-linear-gradient(
    right,
    #3d3d3d,
    #3f3e41 25%,
    #736f73 40%,
    #222e4a 55%,
    #7283ab 65%,
    #58678b 75%,
    #485e95 85%,
    #040404 100%
  );
  background-image: -moz-linear-gradient(
    right,
    #3d3d3d,
    #3f3e41 25%,
    #736f73 40%,
    #222e4a 55%,
    #7283ab 65%,
    #58678b 75%,
    #485e95 85%,
    #040404 100%
  );
  background-image: -ms-linear-gradient(
    right,
    #3d3d3d,
    #3f3e41 25%,
    #736f73 40%,
    #222e4a 55%,
    #7283ab 65%,
    #58678b 75%,
    #485e95 85%,
    #040404 100%
  );
  background-image: linear-gradient(
    to right,
    #3d3d3d,
    #3f3e41 25%,
    #736f73 40%,
    #222e4a 55%,
    #7283ab 65%,
    #58678b 75%,
    #485e95 85%,
    #040404 100%
  );
  background-size: 100% 6px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}
.color-line-green {
  background: #f7f9fa;
  height: 6px;
  background-image: -webkit-linear-gradient(
    right,
    #8cbc31,
    #8bbb31 25%,
    #8bbc32 40%,
    #8bbc31 55%,
    #9be019 65%,
    #a1df2f 75%,
    #98eb00 85%,
    #62fd16 100%
  );
  background-image: -moz-linear-gradient(
    right,
    #8cbc31,
    #8bbb31 25%,
    #8bbc32 40%,
    #8bbc31 55%,
    #9be019 65%,
    #a1df2f 75%,
    #98eb00 85%,
    #62fd16 100%
  );
  background-image: -ms-linear-gradient(
    right,
    #8cbc31,
    #8bbb31 25%,
    #8bbc32 40%,
    #8bbc31 55%,
    #9be019 65%,
    #a1df2f 75%,
    #98eb00 85%,
    #62fd16 100%
  );
  background-image: linear-gradient(
    to right,
    #8cbc31,
    #8bbb31 25%,
    #8bbc32 40%,
    #8bbc31 55%,
    #9be019 65%,
    #a1df2f 75%,
    #98eb00 85%,
    #62fd16 100%
  );
  background-size: 100% 6px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}

.color-line-red {
  background: #f7f9fa;
  height: 6px;
  background-image: -webkit-linear-gradient(
    right,
    #df4b4e,
    #e04c50 25%,
    #e04c50 40%,
    #ff4448 55%,
    #ff2f34 65%,
    #ff0f15 75%,
    #ce0000 85%,
    #000000 100%
  );
  background-image: -moz-linear-gradient(
    right,
    #df4b4e,
    #e04c50 25%,
    #e04c50 40%,
    #ff4448 55%,
    #ff2f34 65%,
    #ff0f15 75%,
    #ce0000 85%,
    #000000 100%
  );
  background-image: -ms-linear-gradient(
    right,
    #df4b4e,
    #e04c50 25%,
    #e04c50 40%,
    #ff4448 55%,
    #ff2f34 65%,
    #ff0f15 75%,
    #ce0000 85%,
    #000000 100%
  );
  background-image: linear-gradient(
    to right,
    #df4b4e,
    #e04c50 25%,
    #e04c50 40%,
    #ff4448 55%,
    #ff2f34 65%,
    #ff0f15 75%,
    #ce0000 85%,
    #000000 100%
  );
  background-size: 100% 6px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}

.color-line-orange {
  background: #f7f9fa;
  height: 6px;
  background-image: -webkit-linear-gradient(
    right,
    #eb9542,
    #eb9542 25%,
    #c57f3c 40%,
    #000000 55%,
    #000000 65%,
    #eb9542 75%,
    #eb9542 85%,
    #eb9643 100%
  );
  background-image: -moz-linear-gradient(
    right,
    #eb9542,
    #eb9542 25%,
    #c57f3c 40%,
    #000000 55%,
    #000000 65%,
    #eb9542 75%,
    #eb9542 85%,
    #eb9643 100%
  );
  background-image: -ms-linear-gradient(
    right,
    #eb9542,
    #eb9542 25%,
    #c57f3c 40%,
    #000000 55%,
    #000000 65%,
    #eb9542 75%,
    #eb9542 85%,
    #eb9643 100%
  );
  background-image: linear-gradient(
    to right,
    #eb9542,
    #eb9542 25%,
    #c57f3c 40%,
    #ff8800 55%,
    #ff8800 65%,
    #eb9542 75%,
    #eb9542 85%,
    #eb9643 100%
  );
  background-size: 100% 6px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}

.color-line-purple {
  background: #f7f9fa;
  height: 6px;
  background-image: -webkit-linear-gradient(
    right,
    #976cd1,
    #976cd1 25%,
    #976cd1 40%,
    #c7a7ef 55%,
    #e0cff6 65%,
    #b186f1 75%,
    #996bcf 85%,
    #976cd1 100%
  );
  background-image: -moz-linear-gradient(
    right,
    #976cd1,
    #976cd1 25%,
    #976cd1 40%,
    #c7a7ef 55%,
    #e0cff6 65%,
    #b186f1 75%,
    #996bcf 85%,
    #976cd1 100%
  );
  background-image: -ms-linear-gradient(
    right,
    #976cd1,
    #976cd1 25%,
    #976cd1 40%,
    #c7a7ef 55%,
    #e0cff6 65%,
    #b186f1 75%,
    #996bcf 85%,
    #976cd1 100%
  );
  background-image: linear-gradient(
    to right,
    #976cd1,
    #976cd1 25%,
    #976cd1 40%,
    #b186f1 75%,
    #996bcf 85%,
    #976cd1 100%
  );
  background-size: 100% 6px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}

.contornoblanco {
  text-shadow: -1px -1px 0px #fff, 1px 1px 0px #fff, -1px 1px 0px #fff,
    1px -1px 0px #fff;
  margin-top: 0.3rem;
}

.potencia {
  font-size: 2rem !important;
}
.iconoCards {
  font-size: 1.7rem;
  color: #7f7f8a;
}


.sensorTemp {
  font-size: 5.5rem;
  border: solid;
  border-radius: 50%;
  max-width: 94px;
  margin: auto;
  border-color: #49b1dd #33a4d3 #49afda #50bbe8;
  border-width: 4px;
}

.cursorPointer {
  cursor: pointer;
}
.card .content {
  padding: 0px 15px 0px 15px;
}
.card-stats .stats {
  font-size: 1.1rem;
  white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}
.card-stats .stats i {
  font-size: 1.4rem;
}

.card-stats {
  margin-top: 1rem;
}
.card-stats .numbers {
  font-size: 1em;
  text-align: center;
  white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.card-stats .numbers p {
  font-size: 1em;
  margin: 0;
}

.card-stats .icon-big {
  font-size: 4em;
  min-height: 80px;
}
.card-stats .icon-big i {
  width: auto;
  font-weight: bold;
  line-height: 59px; }

.main-panel {
  background: rgb(255, 255, 255);
}

.fixed-plugin .button-container .github-btn {
  display: inline-block;
  font-size: 14px;
}
/*.fixed-plugin .button-container .github-btn .gh-btn,
.fixed-plugin .button-container .github-btn .gh-count{
    padding: 5px 7px 5px 7px;
}*/
.fixed-plugin .SocialMediaShareButton {
  display: inline-block;
  margin: 0 2px;
}
.fixed-plugin li > a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
}

.all-icons [class*="pe-"] {
  font-size: 40px;
}
.all-icons input {
  border: 0;
}
.all-icons .font-icon-detail {
  text-align: center;
  padding: 45px 0px 30px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  margin: 15px 0;
}
.all-icons .font-icon-detail input {
  margin: 25px auto 0;
  width: 100%;
  text-align: center;
  display: block;
  color: #aaa;
  font-size: 13px;
}

#map {
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
}

.places-buttons .btn {
  margin-bottom: 30px;
}
.sidebar .nav > li.active-pro {
  position: absolute;
  width: 100%;
  bottom: 10px;
}
.sidebar .nav > li.active-pro a {
  background: rgba(255, 255, 255, 0.14);
  opacity: 1;
  color: #ffffff;
}
.sidebar {
  width: 195px;
}
.sidebar .sidebar-wrapper {
  width: 195px;
}
.sidebar .logo .logo-img,
.sidebar .logo .logo-img img {
  width: 100%;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
  text-align: center;
}

.fixed-plugin {
  /* position: absolute;
    top: 180px;
    right: 4px;
    width: 30px;
    background: rgba(0,0,0,.01);
    z-index: 1031;
    border-radius: 8px 0 0 8px; */
  text-align: center;
}
.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 5px;
  border-radius: 0 0 6px 6px;
  width: auto;
}
.dropdown,
.dropup {
  z-index: 1 !important;
}
.fixed-plugin .dropdown-menu {
  /* right: 80px;
    left: auto;
    width: 290px;
    border-radius: 10px;
    padding: 0 10px; */
  /* right: auto; */
  width: 185px;
  /* border-radius: 10px; */
  padding: 0 5px;
  /* color: red; */
  margin: 3px;
  background-color: #07060630;
}
.fixed-plugin .dropdown-menu:after,
.fixed-plugin .dropdown-menu:before {
  right: 10px;
  margin-left: auto;
  left: auto;
}
.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}
.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.footer-dropdown {
  top: -120px !important;
}

.footer-dropdown:before,
.footer-dropdown:after {
  top: 300px !important;
}

.fixed-plugin .dropdown-menu > .active > a,
.fixed-plugin .dropdown-menu > .active > a:hover,
.fixed-plugin .dropdown-menu > .active > a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  max-height: 175px;
  margin: 0 auto;
}

.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
}
.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-white, .badge-white {
  background-color: #ffffff;
}
.fixed-plugin .badge-black, .badge-black {
  background-color: Black;
}
.fixed-plugin .badge-azure, .badge-azure {
  background-color: #1dc7ea;
}
.fixed-plugin .badge-green, .badge-green {
  background-color: #87cb16;
}
.fixed-plugin .badge-orange, .badge-orange {
  background-color: #ffa534;
}
.fixed-plugin .badge-purple, .badge-purple {
  background-color: #9368e9;
}
.fixed-plugin .badge-red, .badge-red {
  background-color: #fb404b;
}
.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}
.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 5px 2px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  text-align: center;
}
.fixed-plugin .pro-title {
  margin: 10px 0 5px 0;
  text-align: center;
}

.fixed-plugin #sharrreTitle {
  text-align: center;
  padding: 10px 0;
  height: 50px;
}

.fixed-plugin li.header-title {
  height: 30px;
  line-height: 40px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  /* float: left !important; */
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
}
.fixed-plugin .adjustments-line .switch {
  float: right;
}
.fixed-plugin .dropdown-menu > li.adjustments-line > a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  margin: 0;
}
.fixed-plugin .dropdown-menu > li > a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  max-height: 86px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu > li > a.switch-trigger:hover,
.fixed-plugin .dropdown-menu > li > a.switch-trigger:focus {
  background-color: transparent;
}
.fixed-plugin .dropdown-menu > li:hover > a.img-holder,
.fixed-plugin .dropdown-menu > li:focus > a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}
.fixed-plugin .dropdown-menu > .active > a.img-holder,
.fixed-plugin .dropdown-menu > .active > a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}

.fixed-plugin .dropdown-menu > li > a img {
  margin-top: auto;
}

.fixed-plugin .btn-social {
  width: 50%;
  display: block;
  width: 48%;
  float: left;
  font-weight: 600;
}
.fixed-plugin .btn-social i {
  margin-right: 5px;
}
.fixed-plugin .btn-social:first-child {
  margin-right: 2%;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  opacity: 1;
}

@media (min-width: 992px) {

  .fixed-plugin .dropdown.open .dropdown-menu {
    opacity: 1;
    transform-origin: 0 0;
  }

  .fixed-plugin .dropdown-menu:before,
  .fixed-plugin .dropdown-menu:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    width: 16px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
  }

  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }

  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }

  .fixed-plugin {
    top: 300px;
  }
  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }

  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
}

@media (max-width: 991px) {


  .nav-open .sidebar {
    width: 232px;
  }
  .fixed-plugin .dropdown-menu {
    right: 60px;
    width: 220px;
  }
  .fixed-plugin .dropdown-menu li {
    width: 50%;
  }

  .fixed-plugin li.adjustments-line,
  .fixed-plugin li.header-title,
  .fixed-plugin li.button-container {
    width: 100%;
    height: 55px;
    min-height: inherit;
  }

  .fixed-plugin .adjustments-line .switch {
    float: right;
    margin: 0 0px;
  }

  .fixed-plugin li.header-title {
    height: 40px;
  }
  .fixed-plugin .dropdown .dropdown-menu {
    top: -170px;
  }
}

.btn-social {
  opacity: 0.85;
  padding: 8px 9px;
}
.btn-social .fa {
  font-size: 18px;
  vertical-align: middle;
  display: inline-block;
}
.btn-social.btn-round {
  padding: 9px 10px;
}
.btn-social.btn-simple {
  padding: 9px 5px;
  font-size: 16px;
}
.btn-social.btn-simple .fa {
  font-size: 20px;
  position: relative;
  top: -2px;
  width: 24px;
}

.btn-facebook {
  border-color: #3b5998;
  color: #3b5998;
}
.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active,
.btn-facebook.active,
.open > .btn-facebook.dropdown-toggle {
  background-color: transparent;
  color: #3b5998;
  border-color: #3b5998;
  opacity: 1;
}
.btn-facebook:disabled,
.btn-facebook[disabled],
.btn-facebook.disabled {
  background-color: transparent;
  border-color: #3b5998;
}
.btn-facebook.btn-fill {
  color: #ffffff;
  background-color: #3b5998;
  opacity: 0.9;
}
.btn-facebook.btn-fill:hover,
.btn-facebook.btn-fill:focus,
.btn-facebook.btn-fill:active,
.btn-facebook.btn-fill.active,
.open > .btn-facebook.btn-fill.dropdown-toggle {
  background-color: #3b5998;
  color: #ffffff;
  opacity: 1;
}
.btn-twitter {
  border-color: #55acee;
  color: #55acee;
}
.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active,
.btn-twitter.active,
.open > .btn-twitter.dropdown-toggle {
  background-color: transparent;
  color: #55acee;
  border-color: #55acee;
  opacity: 1;
}
.btn-twitter:disabled,
.btn-twitter[disabled],
.btn-twitter.disabled {
  background-color: transparent;
  border-color: #55acee;
}
.btn-twitter.btn-fill {
  color: #ffffff;
  background-color: #55acee;
  opacity: 0.9;
}
.btn-twitter.btn-fill:hover,
.btn-twitter.btn-fill:focus,
.btn-twitter.btn-fill:active,
.btn-twitter.btn-fill.active,
.open > .btn-twitter.btn-fill.dropdown-toggle {
  background-color: #55acee;
  color: #ffffff;
  opacity: 1;
}

.all-icons [class*="pe-"] {
  font-size: 40px;
}
.all-icons input {
  border: 0;
}
.all-icons .font-icon-detail {
  text-align: center;
  padding: 45px 0px 30px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  margin: 15px 0;
}
.all-icons .font-icon-detail input {
  margin: 25px auto 0;
  width: 100%;
  text-align: center;
  display: block;
  color: #aaa;
  font-size: 13px;
}

#map {
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
}

.places-buttons .btn {
  margin-bottom: 30px;
}
.sidebar .nav > li.active-pro {
  position: absolute;
  width: 100%;
  bottom: 10px;
}
.sidebar .nav > li.active-pro a {
  background: rgba(255, 255, 255, 0.14);
  opacity: 1;
  color: #ffffff;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
  text-align: center;
}
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #5d5d5d;
}

.react-toggle--checked .react-toggle-track {
  background-color: #1fc6ea;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #1fc6ea;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;

  outline: 0;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #1fc6ea;
}

.github-btn {
  font: bold 11px/14px "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 20px;
  overflow: hidden;
}
.gh-btn,
.gh-count,
.gh-ico {
  float: left;
}
.gh-btn,
.gh-count {
  padding: 2px 5px 2px 4px;
  color: #333;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
}
.gh-btn {
  background-color: #eee;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #fcfcfc),
    color-stop(100%, #eee)
  );
  background-image: -webkit-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: -moz-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: -ms-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: -o-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: linear-gradient(to bottom, #fcfcfc 0, #eee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc', endColorstr='#eeeeee', GradientType=0);
  background-repeat: no-repeat;
  border: 1px solid #d5d5d5;
}
.gh-btn:hover,
.gh-btn:focus {
  text-decoration: none;
  background-color: #ddd;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #eee),
    color-stop(100%, #ddd)
  );
  background-image: -webkit-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: -moz-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: -ms-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: -o-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: linear-gradient(to bottom, #eee 0, #ddd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#dddddd', GradientType=0);
  border-color: #ccc;
}
.gh-btn:active {
  background-image: none;
  background-color: #dcdcdc;
  border-color: #b5b5b5;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
}
.gh-ico {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQwcHgiIGhlaWdodD0iNDBweCIgdmlld0JveD0iMTIgMTIgNDAgNDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMTIgMTIgNDAgNDAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiMzMzMzMzMiIGQ9Ik0zMiAxMy40Yy0xMC41IDAtMTkgOC41LTE5IDE5YzAgOC40IDUuNSAxNS41IDEzIDE4YzEgMC4yIDEuMy0wLjQgMS4zLTAuOWMwLTAuNSAwLTEuNyAwLTMuMiBjLTUuMyAxLjEtNi40LTIuNi02LjQtMi42QzIwIDQxLjYgMTguOCA0MSAxOC44IDQxYy0xLjctMS4yIDAuMS0xLjEgMC4xLTEuMWMxLjkgMC4xIDIuOSAyIDIuOSAyYzEuNyAyLjkgNC41IDIuMSA1LjUgMS42IGMwLjItMS4yIDAuNy0yLjEgMS4yLTIuNmMtNC4yLTAuNS04LjctMi4xLTguNy05LjRjMC0yLjEgMC43LTMuNyAyLTUuMWMtMC4yLTAuNS0wLjgtMi40IDAuMi01YzAgMCAxLjYtMC41IDUuMiAyIGMxLjUtMC40IDMuMS0wLjcgNC44LTAuN2MxLjYgMCAzLjMgMC4yIDQuNyAwLjdjMy42LTIuNCA1LjItMiA1LjItMmMxIDIuNiAwLjQgNC42IDAuMiA1YzEuMiAxLjMgMiAzIDIgNS4xYzAgNy4zLTQuNSA4LjktOC43IDkuNCBjMC43IDAuNiAxLjMgMS43IDEuMyAzLjVjMCAyLjYgMCA0LjYgMCA1LjJjMCAwLjUgMC40IDEuMSAxLjMgMC45YzcuNS0yLjYgMTMtOS43IDEzLTE4LjFDNTEgMjEuOSA0Mi41IDEzLjQgMzIgMTMuNHoiLz48L3N2Zz4=");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.gh-count {
  position: relative;
  display: none; /* hidden to start */
  margin-left: 4px;
  background-color: #fafafa;
  border: 1px solid #d4d4d4;
}
.gh-count:hover,
.gh-count:focus {
  color: #4183c4;
}
.gh-count:before,
.gh-count:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.gh-count:before {
  top: 50%;
  left: -3px;
  margin-top: -4px;
  border-width: 4px 4px 4px 0;
  border-right-color: #fafafa;
}
.gh-count:after {
  top: 50%;
  left: -4px;
  z-index: -1;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #d4d4d4;
}
.github-btn-large {
  height: 30px;
}
.github-btn-large .gh-btn,
.github-btn-large .gh-count {
  padding: 3px 10px 3px 8px;
  font-size: 16px;
  line-height: 22px;
  border-radius: 4px;
}
.github-btn-large .gh-ico {
  width: 20px;
  height: 20px;
}
.github-btn-large .gh-count {
  margin-left: 6px;
}
.github-btn-large .gh-count:before {
  left: -5px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
}
.github-btn-large .gh-count:after {
  left: -6px;
  margin-top: -7px;
  border-width: 7px 7px 7px 0;
}
.tempCardHeader{
  display: flex;
  justify-content: space-around;
  font-size:2rem;
  line-height: 2px;
}

.AirCardHeader{
  display: flex;
  font-size: 1.3rem;
  flex-wrap: wrap;
  min-height: 80px;
  align-content: space-around;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}