.estiloPowCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: auto;
    height: 60px;
    flex-direction: column;
    min-height: 8rem;
  }

  /* .card-stats .numbers p {
    font-size: 1.1em !important;
    margin: 0;
} */