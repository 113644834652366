.BoxEscenarios{
    /* margin-top: 1rem; */
    width: 80%;
    margin: 1rem auto 1rem auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.itemBtnEscenarios{
    width: 5rem;
    height: 6rem;
    /* display: inline-block; */
    margin: 0.5rem;
    background-color: #f7f7f7;
    box-shadow: 0.6px 0.6px 0px #a4a4a4;
    /* vertical-align: bottom; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around
}
.itemBtnEscenarios:active{

    box-shadow: inset 0.6px 0.6px 0px #a4a4a4;
}


.imgBtnEscenarios{
    width: 65%;
    /* display: block;
    margin: auto; */
}

.itemBtnEscenarios p {
    font-size: 1rem;
    line-height: 1;
    text-transform: lowercase;
    margin-bottom: 0px;
    word-wrap: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}

@media (max-width: 991px){
    .BoxEscenarios{
        width: 98%;
    }
}
