

.ledTitle{
    background: #CF0404;
    background: linear-gradient(to right, #CF0404 0%, #FF3686 50%, #6800CF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
font-weight: bold;
}

.ledTitleInaccessible{
    background: #9b9b9b;
    /* background: linear-gradient(to right, #CF0404 0%, #FF3686 50%, #6800CF 100%); */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
font-weight: bold;
}

.btnLed{
    margin: 1rem;
    background: #1CB5E0;
    height: 4rem;
    width: 20rem;
    color: white;
    border: none;
    font-weight: bold;

}

.btnLed:hover{
    background: #199bbe;
    height: 4.05rem;
    width: 20.5rem;
    color: white;
transition: all 0.1s

}

.btnBox{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.selected{
    background-color:red
}

.btnColor{
    padding: 1rem;
    border-radius: 50%;
    border: solid;
    margin: 1rem;
    border-width: 5px;
    font-weight: bold;
    cursor:pointer;
    border-color: white;
    text-align: center;
    width:5.2rem
}

.activeColor{
    border-color: #ffffff78;
    border-color: linear-gradient(to right, #F8FFAE, #43C6AC);
    border-color: -webkit-linear-gradient(to right, #F8FFAE, #43C6AC);
    /* width:6rem; */
    transition:all .6s;
    
    /* border-radius: 50% 50% 0 0; */

}

.modal-loading {
    background: #ffffffb3;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    font-size: 5rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: cadetblue;
}