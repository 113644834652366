
.SelectPlanos {
  position: absolute;
  border: solid;
  border-color: #ffffff2e;
  margin: 0.3rem;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  color: #717171;
  padding: 2px 2px;
  font-weight: 600;
  font-size: 1.3rem;
}

/* /////CLASES ICONOS EN PLANO/// */
.LUZON {
  color: rgb(215 208 0);
  /* box-shadow: rgba(226, 242, 0, 0.89) 0px 0px 37px 0px; */
  box-shadow: rgb(226 242 0 / 89%) 12px 12px 20px 7px;
  
}
.LUZOFF {
  color: black;
}

.ACON,.DAON,.REMOTEON,.CORTINAON,.TURBINAON, .CAFEON, .VENTON, .DRON {
  /* color: rgb(0, 196, 255); */
  color: rgb(0 153 255)
}
.ACOFF,.DAOFF,.REMOTEOFF,.CORTINAOFF,.TURBINAOFF, .CAFEOFF, .VENTOFF, .DROFF {
  color: rgb(255, 0, 0);
}
.PIRON {
  color: green;
}
.PIROFF {
  color: rgb(0, 196, 255);
}
.DOORON {
  color: rgb(255, 0, 0);
}
.DOOROFF {
  color: green;
}
.SMOKEON {
  color: rgb(255, 0, 0);
}
.SMOKEOFF {
  color: green;
}
  /* .DAON {
    color: rgb(0, 196, 255);
  } */
  /* .DAOFF {
    color: rgb(255, 0, 0);
  } */
/* .REMOTEON {
  color: rgb(0, 196, 255);
} */
/* .REMOTEOFF {
  color: rgb(255, 0, 0);
} */
/* .CORTINAON {
  color: rgb(0, 196, 255);
} */
/* .CORTINAOFF {
  color: rgb(255, 0, 0);
} */
/* .TURBINAON {
  color: rgb(38, 125, 255);
} */
/* .TURBINAOFF {
  color: rgb(255, 0, 0);
} */

.curtainStyle{
  position: absolute;
  /* width: 2rem; */
  font-size: 0.8rem;
  background-color: #ffffffe3;
  color: #098ee4;
  border: solid;
  padding: 0.06rem;
  border-width: 0.1rem;
  border-radius: 0.3rem
}

.tempstyle{
  position: absolute;
  width: 9rem;
  font-size: 1.1rem;
  background-color: #ffffffe3;
  color: #098ee4;
  border-radius: 0 0.5rem 0 0.5rem;
  border: solid;
  padding: 0.06rem;
  border-width: 0.1rem;
}
.airqstyle{
  color: #098ee4;
  position: absolute;
  /* width: 9rem; */
  font-size: 1.2rem;
  background-color: #ffffffe3;
  /* border-radius: 0 0.5rem 0 0.5rem; */
  border: solid;
  padding: 0.02rem;
  border-width: 0.1rem;
  min-width: 6rem;
}

.wledstyle{
  color: #098ee4;
  position: absolute;
  /* width: 9rem; */
  font-size: 2.2rem;
  background-color: #ffffffe3;
  border-radius: 0 0.5rem 0 0.5rem;
  /* border: solid; */
  padding: 0.02rem;
  border-width: 0.1rem;
  min-width: 6rem;
  cursor: pointer;
}

.wledstyle span{
  background: #CF0404;
  background: linear-gradient(to right, #CF0404 0%, #FF3686 50%, #6800CF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.wledstyleInaccessible span{
  background: #9b9b9b;
  /* background: linear-gradient(to right, #CF0404 0%, #FF3686 50%, #6800CF 100%); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
font-weight: bold;
}

.dualStyle{
  position: absolute;
  font-size: 0.9rem;
  /* background-color: #ffffffe3;
  color: #098ee4; */
  border-radius: 0 0.5rem 0 0.5rem;
  /* border: solid; */
  padding: 0.2rem;
  border-width: 0.1rem;
  font-weight: bold;
  display: inline-flex;
  justify-content: space-between;
  width: 6.5rem;
}

.plano .fa-walking:before,
.plano .fa-gripfire:before,
.plano .fa-running:before,
.plano .fa-male:before,
.plano .fa-toggle-on:before,
.plano .fa-wind:before,
.plano .fa-water:before,
.plano .fa-fan:before,
.plano .fa-dice-d6:before,
.plano .fa-power-off:before,
.plano .fa-lightbulb:before,
.plano .fa-plug:before,
.plano .fa-male:before,
.plano .fa-door-open:before,
.plano .fa-door-closed:before,
.plano .fa-coffee:before,
.plano .fa-snowflake:before,
.plano.fa-running:before {
  /* background-color: #ffffff75; */
  background-color: #fffffff7;
  border-radius: 50%;
  padding: 0.1em;
  border: solid;
  border-width: 2px;
  font-size: 1.6rem;
  position: absolute;
  width: 1.8em;
  height: 1.8em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.plano {
  width: 95%;
  position: relative;
  margin: auto;
}

.planoActiveButton {
  position: absolute !important;
  bottom: 0;
  right: 0;
  z-index: 10000; /* Asegurar que el botón aparezca encima del componente */
  background-color: rgba(255, 255, 255, 0.69);
  padding: 0.51rem;
  margin:.4rem
}

.salirButton{
  width: 5rem;
    float: left;
    margin: 0.3rem;
    position: absolute;
    bottom: 0px;
}
@media (max-width: 991px) {
    .plano {
      width: 85% !important;
    }
    .salirButton{
      width: 4rem;
        float: left;
        margin: 0.3rem;
        position: absolute;
        bottom: 0px;
        font-size: 1.2rem;
    }

}
@media (min-width: 1281px) {
  .plano {
    width: 72% !important;
  }

}

@media (max-width: 450px) {
  .salirButton{

      bottom: -20px;
      margin:0

  }
  .planoActiveButton {
    /* font-size: .8rem;
    margin: 0.1rem;
  padding: 0.31rem;
  margin:.2rem */
  display: none;
  }
    .plano .fa-gripfire:before,
    .plano .fa-walking:before,
    .plano .fa-running:before,
    .plano .fa-male:before,
    .plano .fa-toggle-on:before,
    .plano .fa-wind:before,
    .plano .fa-water:before,
    .plano .fa-fan:before,
    .plano .fa-dice-d6:before,
    .plano .fa-power-off:before,
    .plano .fa-lightbulb:before,
    .plano .fa-plug:before,
    .plano .fa-male:before,
    .plano .fa-door-open:before,
    .plano .fa-coffee:before,
   .plano .fa-snowflake:before,
    .plano .fa-door-closed:before,
    .plano.fa-running:before {
      font-size: 0.8rem !important;
      border-width: 0.1rem !important;
      padding: 2px !important;
    }
    .btn-plano {
      font-size: 0.7rem !important;
      padding: 0px 3px !important;
    }
    .SelectPlanos {
        font-size: 1rem !important;
        padding: 0px 0px !important;
      }
     .tempstyle{
       font-size: .7rem;
       width: 6rem;
     }
     .airqstyle{
      font-size: .7rem;
      min-width: 4rem;
      padding: 0px
    }
    .wledstyle{
      font-size: .7rem;
      min-width: 4rem;
      padding: 0px
    }
     .dualStyle{
      font-size: .3rem;
      width:auto
    }
    .curtainStyle{
      font-size: .2rem;
      width:auto
    }
}