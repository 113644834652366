/* CONTROL REMOTO */
.remoteControl {
    background-color: #47b2e1;
    height: 48rem;
    width: 25rem;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 2rem;
    border-radius: 2rem;
    box-shadow: 2px 1px 4px 0px #6a6a6a;
  }
  
  .remoteControl .prender {
    right: -80%;
    top: 1rem;
    position: relative;
    font-size: 4rem;
    color: white;
  }
  .remoteControl .subirVolumen {right: -22%; top: 8rem; border-top-left-radius: 2rem; border-top-right-radius: 2rem;}
  .remoteControl .bajarVolumen {right: -5.9%; top: 13rem; border-bottom-left-radius: 2rem; border-bottom-right-radius: 2rem;}
  .remoteControl .siguiente {right: -2%; top: 8rem; border-top-left-radius: 2rem; border-top-right-radius: 2rem;}
  .remoteControl .anterior {border-bottom-left-radius: 2rem; border-bottom-right-radius: 2rem; right: 14%; top: 13rem;}
  .remoteControl .num0 {right: -68%; top: 32rem;}
  .remoteControl .num1 {right: -39%; top: 17rem;}
  .remoteControl .num2 {right: -42%; top: 17rem;}
  .remoteControl .num3 {right: -45%; top: 17rem;}
  .remoteControl .num4 {right: -1%; top: 22rem;}
  .remoteControl .num5 {right: -4%; top: 22rem;}
  .remoteControl .num6 {right: -7%; top: 22rem;}
  .remoteControl .num7 {right: 37%; top: 27rem;}
  .remoteControl .num8 {right: 34%; top: 27rem;}
  .remoteControl .num9 {right: 31%; top: 27rem;}
  .remoteControl .cool {right: 9%; top: 13rem;}
  .remoteControl .heat {right: 9%; top: 14rem;}
  .remoteControl .dry {right: 9%; top: 15rem;}
  .remoteControl .vent {right: 9%; top: 16rem;}
  .remoteControl .source {right: 9%; top: -2rem;}
  .remoteControl .mute {right: 9%; top: -1rem;}
  .remoteControl .back {right: 9%; top: 0rem;}
  .remoteControl .menu {right: 9%; top: 1rem;}
  .remoteControl i:active,.remoteControl div:active{box-shadow: inset 1px 1px 4px 0px #6a6a6a;}
  
  .btndefault{
    background-color: white; 
    height: 5rem;
    padding: 0.7rem;
    box-shadow: 1px 1px 4px 0px #6a6a6a;
    position: relative;
    font-size: 3rem;
    color: #47b2e1;
    background-color: white;
  }
  
  .numerobtn {
    position: relative;
    font-size: 2.5rem;
    font-family: sans-serif;
    background: #fff;
    color: #2c84ab;
    border-radius: 4px;
    padding: 9px;
    box-shadow: 2px 1px 4px 0px #6a6a6a;
  }
  
  .extrabtn {
    width: 7rem;
    font-size: 1.8rem;
    padding: 0.3em;
    position: relative;
    left: 34px;
    top: 137px;
    background: #fff;
    color: #2c84ab;
    text-align: center;
    border-radius: 1rem;
    box-shadow: 2px 1px 4px 0px #6a6a6a;
  }
  /* FIN CONTROL REMOTO */