.compass {
    width: 60px;
    height: 60px;
    border: 0px solid black;
    border-radius: 50%;
    position: relative;
    margin: 1rem 0;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.8), rgba(0, 0, 0, 0.1));
  }
  .containerStation{
    display: flex;
    justify-content: space-evenly;;
  }
  .needle {
    position: absolute;
    width: 3px;
    height: 26%;
    background-color: red;
    top: 25%;
    left: 46%;
    transform-origin: center bottom;
    transition: transform 0.6s ease;
  }
  .needle:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-right: 7px solid transparent;
    border-left: 7px solid red;
    border-top: 7px solid transparent;
    top: 2px;
    left: -8px;
    transform: rotate(139deg);
  }
  .direction-labels {
    position: absolute;
    width: 100%;
    text-align: center;
    height: 100%;
    font-size: 1rem;
  }
  
  .direction-label {
    position: absolute;
    font-weight: bold;
    /* font-size: 1.2em; */
  }
  
  .direction-label:nth-child(1) {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .direction-label:nth-child(2) {
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
  }
  
  .direction-label:nth-child(3) {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .direction-label:nth-child(4) {
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }
  