.titleBuildingMap{
    font-weight: bold;
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1rem;
}

.attrSensorMap{
    font-size: 1.5rem;
    font-weight: bold;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    /* grid-auto-rows: minmax(100px, auto); */
    color: grey;
}
/* .boxAttrSensorMap{
    margin-bottom:2rem
} */

.titleAttrSensorMap{
    font-weight:bold;
    margin-bottom:1rem;
}

.boxsetAttrSensorMap hr {
    margin-top: 10px;
    margin-bottom: 10px;
}

.boxSensorMap{
    min-width: 550px
}