.editarIconoDispositivo {
    float: right;
    padding: 3px 3px 0px 0px;
    color: #a9a9a99e;
    cursor: pointer;
    right: 1.5rem;
    position: absolute;
    z-index: 1;
  }

  /* .HistoricsIconoDispositivo {
      color: #bfbfbf;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
  }  */

  .HistoricsIconoDispositivo {
    color: #757575;
    cursor: pointer;
    display: flex;
    font-size: 1.1rem;
    background: #f1f1f1;
    padding: 0.3rem;
    /* margin: 4px; */
    border-radius: 5px 5px 0 0;
    /* width: 13rem; */
    /* margin: auto; */
    justify-content: center;
    align-items: center;
} 

#modalHistorics .modal-dialog {
  width: 80%;
  margin: 30px auto;
}