.active-Mobile {
    transition: 0.5s;
    color: white !important;
    border-radius: 1rem;
  }
  .MobileNav a {
    font-size: 2.5rem;
    width: 4rem;
    height: 4rem;
    margin: auto;
    /* border: solid; */
    /* border-radius: 50%;
    border-width: 1px; */
    text-align: center;
    color: Black;
    /* box-shadow: -1px 1px 1px 0px #c1c1c1; */
  }
  .MobileNav a i {
    vertical-align: middle;
  }
  .MobileNav {
    width: 100%;
    bottom: 0px;
    background-color: white;
    height: 6rem;
    position: fixed;
    border-top: solid;
    border-top-width: 0.1px;
    border-top-color: #bebebe;
    display: none;
    justify-content: space-between;
    box-shadow: -1px 2px 12px 0px #c1c1c1;
  }
  

  @media (max-width: 450px) {
    .MobileNav {
        display: flex;
      }
  }