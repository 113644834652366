.attrsContainer{
    display:flex;
    justify-content: space-evenly;
    flex-wrap: wrap
}

.attrButton{
    width: 12rem;
    border: solid;
    text-align: center;
    padding: 2px;
    color: grey; 
    cursor: pointer;
    margin-bottom: 1rem;
    user-select: none;
}

.attrButton:hover{
    /* font-weight: bolder; */
    transition-duration: .5s;
    color: white; 
    background-color:#33a7c5;
    border-color:#33a7c5
}

.attrButton:active{
    transform: scale(0.98);
    transition-duration: .1s;
    box-shadow: inset 2px 2px 0px 0px #33a7c5;
    background-color: #33a7c5
}

.attrButtonActive{
    background-color:#33a7c5;
    color: white;
    border-color:#33a7c5
}

.btnPredActive{
    background-color:rgb(0, 145, 182) !important;
    color: white !important;
}